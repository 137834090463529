<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8" v-if="!this.autoInc">
        <el-button size="small" type="primary" plain v-if="formStatus === 2" @click="generatePinvChange">生成发票变更 </el-button>
        <el-button size="small" type="primary" plain @click="generatePaymentNote" v-if="formStatus === 2">生成付款单 </el-button>
        <el-button v-if="formStatus === 2" icon="el-icon-printer" size="small" type="info" @click="exportPinvNotice">打印开票通知单</el-button>
        <el-button v-if="formStatus === 2" icon="el-icon-bottom" size="small" type="primary" @click="clickexportPinvNotice">下载开票通知单PDF</el-button>
        <el-button v-if="formStatus === 2" icon="el-icon-printer" size="small" type="info" @click="exporPrint">打印开票通知单(公斤)</el-button>
        <el-button v-if="formStatus === 2" icon="el-icon-bottom" size="small" type="primary" @click="clickexportPrint">下载开票通知单(公斤)PDF</el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>单据号：</span>
          <el-input disabled size="small" :value="pinv_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
      :computedData="{ tran_nos: tran_nos, scon_nos: scon_nos, invoice_nos: invoice_nos, podr_nos: podr_nos, new_ship_no: new_ship_no }"
    />
    <div class="vg_mb_8" style="display: flex">
      <el-button type="primary" size="small" :disabled="isShow" @click="addRow">新增</el-button>
      <el-button :disabled="isShow" class="vg_mr_8" plain size="small" type="danger" @click="delRow">删除</el-button>
      <!--<el-button type="primary" plain size="small" :disabled="isShow" @click="accessoriesDialogVisible = true"> 辅料转开导入 </el-button>-->
      <div v-if="Number(order_type) === 0">
        <el-button :disabled="isShow" plain size="small" type="primary" @click="actualDialogVisible = true">实际收票导入</el-button>
        <el-button :disabled="isShow" plain size="small" type="primary" @click="tranPartDialogVisible = true"> 导入报关明细</el-button>
        <el-button :disabled="isShow" plain size="small" type="success" @click="surcharge">含入附加费</el-button>
        <el-button :disabled="isShow" plain size="small" type="warning" @click="copyRow">复制</el-button>
      </div>
    </div>
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="pinvSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
      :computedData="{
        pinv_part_subtotal: pinv_part_subtotal,
        pinv_actu_price: pinv_actu_price,
        pinv_part_no_subtotal: pinv_part_no_subtotal,
        tax_accrued: tax_accrued,
        tax_refund: tax_refund,
        prod_usubtotal: prod_usubtotal,
        pinv_actu_accrued: pinv_actu_accrued
      }"
      @row-click="(row, column, event) => (currentRow = row)"
      :needAllColumnSummary="false"
      :show-summary="true"
      :summariesColumns="[
        'pinv_part_num',
        'pinv_part_money',
        'prod_tgweight',
        'prod_tnweight',
        'pinv_actu_num',
        'pinv_actu_subtotal',
        'pinv_part_no_subtotal',
        'pinv_actu_no_subtotal',
        'tax_accrued',
        'tax_refund',
        'prod_usubtotal',
        'tax_accrued',
        'tax_refund',
        'pinv_actu_accrued'
      ]"
      :needSummaryValues="['pinv_part_num', 'pinv_part_money', 'pinv_actu_num', 'pinv_actu_subtotal', 'pinv_actu_accrued']"
      @getSummary_pinv_part_num="changePinvPartNum"
      @getSummary_pinv_part_money="changePinvPartMoney"
      @getSummary_pinv_actu_num="changePinvActuNum"
      @getSummary_pinv_actu_subtotal="changePinvActuSubtotal"
      @getSummary_pinv_actu_accrued="changeTaxAccrued"
      :otherHeaderCellStyle="headerCellStyle"
    >
      <template v-slot:subItem-prod_hscode="scope">
        <el-link type="primary" class="vg_cursor" :disabled="isShow" @click="showTranPartDialog(scope.$index)">选择 </el-link>
      </template>
    </DynamicUTable>
    <el-dialog title="出运明细" width="70%" :visible.sync="tranPartDialogVisible" @opened="getTranPartTableData">
      <el-button class="vg_mb_8" type="primary" size="small" icon="el-icon-refresh-right" @click="tranPartImport()"> 确认选择 </el-button>
      <el-button class="vg_mb_8" type="info" size="small" icon="el-icon-refresh-right" @click="tranPartRefresh()"> 刷新 </el-button>
      <search-table
        ref="tranPartTable"
        :data="tranPartTableData"
        :tableRowKey="'tranPart_id'"
        :columns="tranPartTableProperties"
        v-loading="tranPartLoadFlag"
        :totalPage="tranPartTotalPage"
        :need-fixed-height="true"
        @selection-change="tranPartSelectionChange"
        @getTableData="getTranPartTableData"
      />
    </el-dialog>
    <el-dialog title="辅料转开" width="70%" :visible.sync="accessoriesDialogVisible" @opened="getAccessories">
      <search-table
        ref="multiTable"
        :data="accessoriesDataList"
        :tableRowKey="'accessories'"
        :columns="mtrlTableProperties"
        v-loading="accessoriesLoadFlag"
        @row-dblclick="accessoriesImport"
        @getTableData="() => {}"
        :needPagination="false"
        :needSearch="false"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="实际收票" width="70%" :visible.sync="actualDialogVisible" @opened="getActualTableData">
      <el-button class="vg_mb_8" top="10%" type="info" size="small" icon="el-icon-refresh-right" @click="actualRefresh()"> 刷新 </el-button>
      <search-table
        ref="actualTable"
        :data="actualTableData"
        :tableRowKey="'actual_id'"
        :columns="actualTableProperties"
        v-loading="actualLoadFlag"
        :totalPage="actualTotalPage"
        :need-fixed-height="true"
        @row-dblclick="actualImport"
        @selection-change="actualSelectionChange"
        @getTableData="getActualTableData"
      />
    </el-dialog>
    <el-dialog title="托收合同" width="70%" :visible.sync="collectionContractVisible" @opened="getCollectionContractTableData">
      <el-button class="vg_mb_8" icon="el-icon-refresh-right" size="small" top="10%" type="info" @click="collectionContractRefresh()"> 刷新</el-button>
      <search-table
        ref="collectionContract"
        :data="collectionContractTableData"
        :tableRowKey="'contract_id'"
        :columns="collectionContractTableProperties"
        v-loading="collectionContractLoadFlag"
        :totalPage="collectionContractTotalPage"
        :need-fixed-height="true"
        @row-dblclick="collectionContractImport"
        @selection-change="collectionContractSelectionChange"
        @getTableData="getCollectionContractTableData"
      />
    </el-dialog>
    <el-dialog title="海关编码" width="70%" :visible.sync="dialogTableVisible">
      <customsList @changeCustom="changeCustom"></customsList>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser.vue';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import {
  accessoriesTableProperties,
  actualTableProperties,
  pinvMainFormProperties,
  pinvMtrbSubTableProperties,
  pinvSubTableProperties,
  tranPartTableProperties
} from '@/views/FinanceManagement/PinvManage/pinv';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { pinvAPI } from '@api/modules/pinv';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import customsList from '@/views/FinanceManagement/PinvManage/TabChild/Componet/CustomsList.vue';
import { actualAPI } from '@api/modules/actual';
import { getNoCatch, postNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { BigNumber } from 'bignumber.js';
import { arrayDeRepeat, deleteTableRow, getArrayIds, objectArrayReduce } from '@assets/js/arrayUtils';
import { changeStaffForm, getCptt, getDataCenterByPermId, getDept, getSupp, getUnit } from '@api/public';
import { ppayAPI } from '@api/modules/ppay';
import { tranAPI } from '@api/modules/tran';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'pinvEditMain',
  components: {
    customsList,
    SearchTable,
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      currentRow: {},
      stffForm: {},
      splitForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(pinvMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      formStatus: 0,
      pinv_no: '',
      pinv_id: '',
      supp_id: '',
      requisition_id: '',
      autoInc: '',
      invoice_no: '',
      order_type: '',
      actualDialogVisible: false,
      actualTotalPage: 0,
      actualLoadFlag: true,
      actualTableData: [],
      actualSelections: [],
      accessoriesLoadFlag: true,
      actualTableProperties: cloneDeep(actualTableProperties),
      tranPartDialogVisible: false,
      tranPartTotalPage: 0,
      tranPartLoadFlag: true,
      tranPartTableData: [],
      tranPartSelections: [],
      tranPartTableProperties: cloneDeep(tranPartTableProperties),
      accessoriesTableProperties: cloneDeep(accessoriesTableProperties),
      accessoriesDialogVisible: false,
      accessoriesDataList: [],
      mtrlTableProperties: cloneDeep(accessoriesTableProperties),
      dialogTableVisible: false,
      collectionContractVisible: false,
      collectionContractTableData: [],
      collectionContractTableProperties: [],
      collectionContractLoadFlag: false,
      collectionContractTotalPage: 0,
      collectionContractSelections: [],
      openIndex: '',
      suppList: []
    };
  },
  computed: {
    pinvSubTableProperties() {
      if (this.order_type === 0) {
        // this.changeProperties();
        return cloneDeep(pinvSubTableProperties);
      } else if (this.order_type === 1) {
        // this.changeProperties();
        return cloneDeep(pinvMtrbSubTableProperties);
      } else return [];
    },
    // 子表出运发票号
    tran_nos() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'tran_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'tran_nos').model = nos;
      return nos;
    },
    // 子表外销发票号
    scon_nos() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'scon_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'scon_nos').model = this.order_type === 1 ? this.sconNos : nos;
      return nos;
    },
    // 子表发票号
    invoice_nos() {
      if (!this.autoInc) {
        let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'invoice_no').filter(x => !!x)).toString();
        this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_nos').model = nos;
        return nos;
      } else {
        this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_nos').model = this.invoice_no;
      }
    },
    // 子表采购合同号
    podr_nos() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'podr_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'podr_nos').model = nos;
      return nos;
    },
    // 子表新出运发票号
    new_ship_no() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'new_ship_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'new_ship_no').model = nos;
      return nos;
    },
    // 收票金额 = 收票数量 * 收票单价 + 附加费
    pinv_part_subtotal() {
      let { pinv_part_num, pinv_part_price, surcharge } = this.currentRow;
      let temp = new BigNumber(pinv_part_num).times(pinv_part_price).plus(surcharge || 0);
      if (!temp.isFinite()) {
        this.currentRow.pinv_part_subtotal = 0;
        return '自动计算';
      }
      this.currentRow.pinv_part_subtotal = temp.toFixed(4);
      return temp.toFixed(4);
    },
    // 实际收票单价 = 实际不含税金额 / 实际收票数量
    pinv_actu_price() {
      if (!this.currentRow) return;
      let { pinv_actu_no_subtotal, pinv_actu_num } = this.currentRow;
      let temp = new BigNumber(pinv_actu_no_subtotal).div(pinv_actu_num);
      if (!temp.isFinite()) {
        this.currentRow.pinv_actu_price = 0;
        return '自动计算';
      }
      this.currentRow.pinv_actu_price = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 不含税金额 = 收票金额 - 纳税额
    pinv_part_no_subtotal() {
      if (!this.currentRow) return;
      let { pinv_part_subtotal, tax_accrued } = this.currentRow;
      // let temp = new BigNumber(pinv_part_subtotal).div(1 + tax_rate);
      let temp = new BigNumber(pinv_part_subtotal).minus(tax_accrued);
      if (!temp.isFinite()) {
        this.currentRow.pinv_part_no_subtotal = 0;
        return '自动计算';
      }
      this.currentRow.pinv_part_no_subtotal = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 纳税额 = 实际收票金额 - (实际收票金额 / ( 1 + 税率 / 100))
    tax_accrued() {
      let { pinv_actu_subtotal, tax_rate } = this.currentRow;
      // let temp = new BigNumber((pinv_actu_subtotal / (1 + tax_rate)) * tax_rate);
      let temp = new BigNumber(pinv_actu_subtotal - pinv_actu_subtotal / (1 + tax_rate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_accrued = 0;
        return '自动计算';
      }
      this.currentRow.tax_accrued = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 退税额 = 实际收票金额 / ( 1 + 退税率 / 100) * ( 退税率 / 100)
    tax_refund() {
      let { pinv_actu_subtotal, tax_refundrate } = this.currentRow;
      // let temp = new BigNumber(pinv_actu_subtotal).times(tax_refundrate).div(100);
      let temp = new BigNumber(pinv_actu_subtotal - pinv_actu_subtotal / (1 + tax_refundrate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_refund = 0;
        return '自动计算';
      }
      this.currentRow.tax_refund = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 报关金额 = 开票数量 * 报关单价
    prod_usubtotal() {
      let { pinv_part_num, prod_usdprice } = this.currentRow;
      let temp = new BigNumber(pinv_part_num).times(prod_usdprice);
      if (!temp.isFinite()) {
        this.currentRow.prod_usubtotal = 0;
        return '自动计算';
      }
      this.currentRow.prod_usubtotal = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 新增实际纳税额 = 实际收票金额 - 实际不含税金额
    pinv_actu_accrued() {
      let { pinv_actu_subtotal, pinv_actu_no_subtotal } = this.currentRow;
      let temp = new BigNumber(pinv_actu_subtotal).minus(pinv_actu_no_subtotal);
      if (!temp.isFinite()) {
        this.currentRow.pinv_actu_accrued = 0;
        return '自动计算';
      }
      this.currentRow.pinv_actu_accrued = temp.toFixed(2);
      return temp.toFixed(2);
    }
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getPinvData();
      await this.changeProperties();
    },
    async changeProperties() {
      this.mainForm.formProperties.find(({ prop }) => prop === 'pinv_cptt_id').options = await getCptt();
      this.mainForm.formProperties.find(({ prop }) => prop === 'cust_dept_id').options = await getDept();
      this.suppList = await getSupp();
      let subTableSupp = this.pinvSubTableProperties.find(({ prop }) => prop === 'supp_id');
      if (subTableSupp) {
        subTableSupp.subItem.options = this.suppList;
        subTableSupp.subItem.change = (val, row) => (row.supp_abbr = this.suppList.find(({ value }) => value === val)?.label ?? '');
      }
      let unitArr = await getUnit('select', 'param1');
      let prod_unit = this.pinvSubTableProperties.find(({ prop }) => prop === 'prod_unit');
      if (prod_unit) prod_unit.subItem.options = unitArr;
      let pinv_part_unit = this.pinvSubTableProperties.find(({ prop }) => prop === 'pinv_part_unit');
      if (pinv_part_unit) pinv_part_unit.subItem.options = unitArr;
      //报关中文品名 & 联动修改海关编码
      let optList = await getDataCenterByPermId({ id: 10019, type: 'no' });
      let prod_name = this.pinvSubTableProperties.find(({ prop }) => prop === 'prod_name');
      if (prod_name) {
        prod_name.subItem.options = Array.from(optList, ({ param2 }) => {
          return { value: param2, label: param2 };
        });
        prod_name.subItem.change = (val, row, options) => {
          let find = optList.find(item => item.param2 === val);
          this.$set(row, 'prod_hscode', find.param1);
        };
      }
      let suppAbbrColumn = this.mainForm.formProperties.find(({ prop }) => prop === 'supp_abbr');
      suppAbbrColumn.options = Array.from(this.suppList, ({ label }) => {
        return { value: label, label: label };
      });
      suppAbbrColumn.change = val => (this.supp_id = this.suppList.find(({ label }) => label === val)?.value);
      //子表发票号change
      let invoice_no = this.pinvSubTableProperties.find(({ prop }) => prop === 'invoice_no');
      if (invoice_no) {
        invoice_no.subItem.change = (val, row, options) => {
          if (!this.mainForm.formProperties.find(({ prop }) => prop === 'pinv_recdate').model) {
            this.mainForm.formProperties.find(({ prop }) => prop === 'pinv_recdate').model = new Date().getTime();
          }
        };
      }
      let mtrb_pctype = this.pinvSubTableProperties.find(({ prop }) => prop === 'mtrb_pctype');
      if (mtrb_pctype) {
        mtrb_pctype.subItem.options = await getDataCenterByPermId({ id: 10024, type: 'valueList' });
      }
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message.info('已撤销编辑');
        })
        .catch(() => {});
    },
    getPinvData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      getNoCatch(pinvAPI.getPinvById, { pinv_id: props.form_id }).then(({ data }) => {
        this.$nextTick(() => {
          this.btn = data.btn;
          this.pinv_no = data.form.pinv_no;
          this.pinv_id = data.form.pinv_id;
          this.supp_id = data.form.supp_id;
          this.order_type = data.form.order_type;
          this.sconNos = data.form.scon_nos;
          this.requisition_id = data.form.requisition_id;
          this.autoInc = data.form.autoInc;
          this.invoice_no = data.form.invoice_nos;
          this.formStatus = data.form.status;
          changeStaffForm(this.stffForm, data.form);
          this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
          if (data.form.autoInc) {
            this.mainForm.formProperties.find(({ prop }) => prop === 'supp_abbr').model = data.form.supp_abbr_rich;
            this.mainForm.formProperties.find(({ prop }) => prop === 'supp_abbr').label = '(旧)供应商简称';
          }
          this.subTableData = data.form.pinvPartList;
          //用友抓取后不可反生效不可编辑
          this.$emit('isYongYou', false);
          this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').model = '未抓取';
          this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').show = false;
          this.subTableData.forEach(item => {
            if (item.npurBillVouch_Status === 1 || item.nrdRecord01_Status === 1) {
              this.btn.edit = false;
              this.$emit('isYongYou', true);
              this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').show = true;
              this.mainForm.formProperties.find(({ prop }) => prop === 'yongYouStatus').model = '已抓取';
            }
          });
        });
      });
    },
    submit(formName) {
      if ([43, 33, 4, 3, 2].includes(this.mainForm.formProperties.find(x => x.label === '公司抬头')?.model)) {
        const checkField = (fieldName, errorMessage) => {
          let flag = false;
          try {
            const fieldValue = objectArrayReduce(this.subTableData, fieldName);
            if (fieldValue <= 0) flag = true;
          } catch (error) {
            console.error(`Error in checkField for ${fieldName}:`, error);
            return true; // 返回 true 表示有错误发生
          }
          if (flag) return this.$message.error(errorMessage);
        };
        if (checkField('pinv_actu_num', '实际收票数量必须大于0')) return;
        if (checkField('pinv_actu_subtotal', '实际收票金额必须大于0')) return;
        if (checkField('pinv_actu_no_subtotal', '实际不含税金额必须大于0')) return;
      }
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      postNoCatch(pinvAPI.editPinv, this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.pinv_id = this.pinv_id;
      formModel.pinv_no = this.pinv_no;
      formModel.supp_id = this.supp_id;
      formModel.requisition_id = this.requisition_id;
      formModel.pinvPartList = this.subTableData.concat(Array.from(this.subTableMap.values()));
      return formModel;
    },
    // 实际收票数据
    async getActualTableData() {
      this.actualLoadFlag = true;
      await actualAPI.get_actual(this.$refs.actualTable.searchForm).then(({ data }) => {
        this.actualTableData = data.list;
        this.actualTotalPage = data.total;
        this.actualLoadFlag = false;
      });
    },
    actualRefresh() {
      this.$refs.actualTable.resetFields();
      this.getActualTableData();
    },
    async actualImport(row) {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      obj.invoice_no = row.tran_no;
      obj.pinv_actu_subtotal = row.pinv_actu_subtotal;
      obj.pinv_actu_price = row.pinv_actu_price;
      obj.pinv_actu_num = row.pinv_actu_num;
      obj.supp_id = row.supp_id;
      obj.supp_abbr = row.supp_abbr;
      obj.prod_unit = row.prod_unit;
      obj.prod_bhsname = row.prod_partno;
      obj.prod_name = row.prod_partno;
      obj.tax_accrued = row.prod_refundtax;
      obj.pinv_part_no_subtotal = row.pinv_part_no_subtotal;
      obj.pinv_actu_no_subtotal = row.pinv_part_no_subtotal;
      this.subTableData.push(obj);
      this.actualDialogVisible = false;
    },
    actualSelectionChange(selection) {
      this.actualSelections = selection;
    },
    async getAccessories() {
      await (this.accessoriesLoadFlag = true);
      await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10028 }).then(({ data }) => {
        this.accessoriesDataList = Array.from(data.form.optn_cntt_list, ({ param1, param2, param3, param4 }) => {
          return { prod_no: param1, prod_cust_no: param2, prod_hsname: param3, prod_ehsname: param4 };
        });
        this.accessoriesLoadFlag = false;
      });
    },
    async accessoriesImport(row) {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      obj.prod_no = row.prod_no;
      obj.prod_cust_no = row.prod_cust_no;
      obj.prod_bhsname = row.prod_bhsname;
      obj.prod_name = row.prod_name;
      obj.supp_abbr = '暂无';
      this.subTableData.push(obj);
      this.accessoriesDialogVisible = false;
    },
    async getCollectionContractTableData() {
      this.collectionContractLoadFlag = true;
      await actualAPI.get_actual(this.$refs.collectionContract.searchForm).then(({ data }) => {
        this.actualTableData = data.list;
        this.actualTotalPage = data.total;
        this.actualLoadFlag = false;
      });
    },
    collectionContractRefresh() {
      this.$refs.collectionContract.resetFields();
      this.getCollectionContractTableData();
    },
    async collectionContractImport(row) {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      this.subTableData.push(obj);
      this.collectionContractVisible = false;
    },
    collectionContractSelectionChange(selection) {
      this.collectionContractSelections = selection;
    },
    changePinvPartNum(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_num').model = Number(val);
    },
    // changePinvPartSubtotal(val) {
    //   this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_price').model = val;
    // },
    changePinvPartMoney(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_price').model = val;
    },
    changePinvActuNum(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'actual_num').model = val;
    },
    changePinvActuSubtotal(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'actual_price').model = val;
    },
    changeTaxAccrued(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'pinv_price').model = Number(val).toFixed(2);
    },
    generatePinvChange() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      getNoCatch(pinvAPI.addPinvChange, { id: props.form_id }).then(({ data }) => {
        this.$message.success('生成成功!');
        this.jump('/pinvchange_edit', {
          key: UrlEncode.encode(JSON.stringify({ perm_id: 165, form_id: data }))
        });
      });
    },
    // 生成付款单
    generatePaymentNote() {
      this.$confirm('确定生成?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          postNoCatch(ppayAPI.addPpay, { pinv_id: this.pinv_id }).then(({ data }) => {
            this.$message.success('生成成功!');
            this.jump('/ppay_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: 137,
                  form_id: data.form_id
                })
              )
            });
          });
        })
        .catch(err => console.error(err));
    },
    // 开票通知单
    exportPinvNotice() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch(pinvAPI.exportPinvNotice, { pinv_id: this.pinv_id }).then(({ data }) => {
            if (process.env.NODE_ENV === 'production') {
              window.open('/erpfile' + data.pdf_url + '.pdf');
            } else {
              window.open('/file' + data.pdf_url + '.pdf');
            }
          });
        })
        .catch(err => console.error(err));
    },
    //导出 下载·
    clickexportPinvNotice() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch(pinvAPI.exportPinvNotice, { pinv_id: this.pinv_id }).then(({ data }) => {
            this.helper.downloadItem(data.pdf_url, '开票通知单.pdf');
          });
        })
        .catch(err => console.error(err));
    },
    // 开票通知单(公斤)
    exporPrint() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch(pinvAPI.exportPinvNoticeKG, { pinv_id: this.pinv_id }).then(({ data }) => {
            if (process.env.NODE_ENV === 'production') {
              window.open('/erpfile' + data.pdf_url + '.pdf');
            } else {
              window.open('/file' + data.pdf_url + '.pdf');
            }
          });
        })
        .catch(err => console.error(err));
    },
    //导出 下载·
    clickexportPrint() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getNoCatch(pinvAPI.exportPinvNoticeKG, { pinv_id: this.pinv_id }).then(({ data }) => {
            this.helper.downloadItem(data.pdf_url, '开票通知单(公斤).pdf');
          });
        })
        .catch(err => console.error(err));
    },
    addRow() {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      console.info('🚀 ~ file:PinvNewEditMain.vue time:上午11:35 line:717 -----', obj);

      obj.tax_rate = 13;
      obj.tax_refundrate = 13;
      this.subTableData.push(obj);
    },
    delRow() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择数据!');
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let { tableData, map } = deleteTableRow('pinv_part_id', this.subTableData, this.subTableMap, this.subSelections);
          this.subTableData = tableData;
          this.subTableMap = map;
        })
        .catch(err => console.error(err));
    },
    // 含入附加费
    surcharge() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择一条数据!');
      let podr_nos = getArrayIds(this.subSelections, 'podr_no').toString();
      if (!podr_nos) return;
      tranAPI.get_fees_sum({ podr_nos: podr_nos }).then(({ data }) => {
        this.subSelections[0].surcharge = data[podr_nos];
        this.$refs.dynamicUTable.$refs.multiTable.setActiveRow(this.subSelections[0]);
        this.currentRow = this.subSelections[0];
        this.$refs.dynamicUTable.clearSelection();
        this.$message.success('已含入');
      });
    },
    copyRow() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择一条数据!');
      let obj = cloneDeep(this.subSelections[0]);
      obj.pinv_part_id = 0;
      obj.pinv_actu_num = 0;
      obj.pinv_actu_subtotal = 0;
      obj.pinv_actu_no_subtotal = 0;
      this.subTableData.push(obj);
      this.$refs.dynamicUTable.clearSelection();
      this.$refs.dynamicUTable.$refs.multiTable.setActiveRow(obj);
      this.currentRow = obj;
    },
    // 报关明细数据
    async getTranPartTableData() {
      this.tranPartLoadFlag = true;
      await getNoCatch(pinvAPI.getAllTranPart, this.$refs.tranPartTable.searchForm).then(({ data }) => {
        this.tranPartTableData = data.list;
        this.tranPartTotalPage = data.total;
        this.tranPartLoadFlag = false;
      });
    },
    tranPartRefresh() {
      this.$refs.tranPartTable.resetFields();
      this.getTranPartTableData();
    },
    tranPartImport() {
      if (this.tranPartSelections.length === 0) return this.$message.warning('请选择一条数据!');
      const length = this.subTableData.length;
      this.tranPartSelections.forEach(async (item, index) => {
        let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
        obj.tran_no = item.tran_no;
        obj.prod_no = item.prod_no;
        obj.scon_no = item.scon_no;
        obj.podr_no = item.podr_no;
        obj.supp_id = item.supp_id;
        obj.supp_abbr = item.supp_abbr;
        obj.prod_bhsname = item.prod_hsname;
        obj.prod_name = item.prod_hsname;
        obj.prod_cust_no = item.prod_new_cust_no;
        obj.prod_hscode = item.prod_hscode;
        obj.pinv_part_num = item.prod_num;
        obj.prod_unit = item.prod_unit;
        obj.pinv_part_price = item.prod_price;
        obj.prod_tnweight = item.prod_tnweight;
        obj.prod_tgweight = item.prod_tgweight;
        obj.prod_usdprice = item.prod_dusd;
        obj.tax_rate = item.prod_hsinrate;
        obj.tax_refundrate = item.prod_hsoutrate;
        this.subTableData.push(obj);
      });
      this.tranPartDialogVisible = false;
    },
    tranPartSelectionChange(selection) {
      this.tranPartSelections = selection;
    },
    // 更换海关编码
    changeCustom(row) {
      this.$set(this.subTableData[this.openIndex], 'prod_hscode', row.param1);
      this.$set(this.subTableData[this.openIndex], 'prod_hsname', row.param2);
      this.$set(this.subTableData[this.openIndex], 'prod_ehsname', row.param3);
      this.$set(this.subTableData[this.openIndex], 'prod_hsinrate', row.param4);
      this.$set(this.subTableData[this.openIndex], 'prod_hsoutrate', row.param5);
      this.dialogTableVisible = false;
    },
    showTranPartDialog(index) {
      this.dialogTableVisible = true;
      this.openIndex = index;
    },
    //列变色
    headerCellStyle(column) {
      if (['发票号'].includes(column.title)) return { color: 'red' };
    }
  }
};
</script>

<style scoped lang="scss"></style>
