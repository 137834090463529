import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const ppayAPI = {
  getPpays: topic.ppayTopic + '/get_ppays',
  getPpayById: topic.ppayTopic + '/get_ppay_by_id',
  addPpay: topic.ppayTopic + '/add_ppay',
  editPpay: topic.ppayTopic + '/edit_ppay',
  deletePpayByIds: topic.ppayTopic + '/delete_ppay_by_ids',
  editPpayPinvInvstat: topic.ppayTopic + '/edit_ppay_pinv_invstat',
  addPpayByOinv: topic.ppayTopic + '/add_ppay_by_oinv',
  getPPayListByRichMax: params => getNoCatch(`${topic.ppayTopic}/getPPayListByRichMax`, params),
  addPPayByRichMax: params => postNoCatch(`${topic.ppayTopic}/addPPayByRichMax`, params)
};
